import React, { useState } from "react";
import axios from "axios";
import { ImSpinner2 } from "react-icons/im";

export default function Admin() {
  const [nomorTransaksi, setNomorTransaksi] = useState("");
  const [nama, setNama] = useState("");
  const [nomorTelpon, setNomorTelpon] = useState("");
  const [judulGame, setJudulGame] = useState("");
  const [usernameGame, setUsernameGame] = useState("");
  const [passwordGame, setPasswordGame] = useState("");
  const [emailGame, setEmailGame] = useState("");
  const [passwordEmailGame, setPasswordEmailGame] = useState("");
  const [hostEmailGame, setHostEmailGame] = useState("");
  const [isLoadingGame, setIsLoadingGame] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingCustomer(true);

    // Data yang akan dikirim
    const newCustomer = {
      nomorTransaksi: nomorTransaksi,
      namaCustomer: nama,
      noTelpon: nomorTelpon, // jika LimitOTP kosong
    };

    try {
      // Mengirimkan POST request ke server
      const response = await axios.post(
        "https://gameotp.rfstore.net/backend/admin/post-customer",
        newCustomer
      );
      console.log("Response:", response.data);
      setIsLoadingCustomer(false);
      alert("Data customer berhasil ditambahkan");

      // Reset form atau melakukan sesuatu setelah submit berhasil
      setNomorTransaksi("");
      setNama("");
      setNomorTelpon("");
    } catch (error) {
      setIsLoadingCustomer(false);
      alert("ERROR : Data customer gagal ditambahkan");
      console.error("There was an error!", error);
    }
  };

  const handleSubmitGame = async (e) => {
    e.preventDefault();
    setIsLoadingGame(true);

    // Data yang akan dikirim
    const newGame = {
      judulGame: judulGame,
      usernameGame: usernameGame,
      passwordGame: passwordGame,
      emailGame: emailGame,
      passwordEmailGame: passwordEmailGame,
      hostEmailGame: hostEmailGame,
    };

    try {
      // Mengirimkan POST request ke server
      const response = await axios.post(
        "https://gameotp.rfstore.net/backend/admin/post-game",
        newGame
      );
      console.log("Response:", response.data);
      setIsLoadingGame(false);
      alert("Data Game berhasil ditambahkan");

      // Reset form atau melakukan sesuatu setelah submit berhasil
      setJudulGame("");
      setUsernameGame("");
      setPasswordGame("");
      setEmailGame("");
      setPasswordEmailGame("");
      setHostEmailGame("");
    } catch (error) {
      setIsLoadingGame(false);
      alert("ERROR : Data Game gagal ditambahkan");
      console.error("There was an error!", error);
    }
  };

  return (
    <main className="p-7">
      <div className="mt-8 flex flex-col gap-7 lg:px-[13rem]">
        <h1 className="text-2xl font-bold text-[#167d5c]">Data Customer</h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-5 w-full">
          <label htmlFor="noTransaksiCustomer" className="font-bold text-md">
            Nomor Transaksi / Nomor Invoice Customer
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="noTransaksiCustomer"
            type="text"
            placeholder="Nomor Transaksi / Nomor Invoice Yang Terdaftar"
            value={nomorTransaksi}
            onChange={(e) => setNomorTransaksi(e.target.value)}
            required
          />
          <label htmlFor="namaCustomer" className="font-bold text-md">
            Nama Customer
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="namaCustomer"
            type="text"
            placeholder="Nama Customer"
            value={nama}
            onChange={(e) => setNama(e.target.value)}
          />
          <label htmlFor="nomorTelponCustomer" className="font-bold text-md">
            No Telpon Customer (opsional)
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="nomorTelponCustomer"
            type="text"
            placeholder="Nomor Telpon Customer"
            value={nomorTelpon}
            onChange={(e) => setNomorTelpon(e.target.value)}
          />
          {isLoadingCustomer ? (
            <button
              className="bg-[#167d5c] flex items-center gap-1 p-2 rounded-xl text-white font-bold w-[6rem] hover:bg-[#0f5c44]"
              disabled
            >
              <ImSpinner2 className="animate-spin" />
              Loading
            </button>
          ) : (
            <button
              type="submit"
              className="bg-[#167d5c] p-2 rounded-xl text-white font-bold w-[6rem] hover:bg-[#0f5c44]"
            >
              Submit
            </button>
          )}
        </form>
      </div>

      <div className="mt-8 flex flex-col gap-7 lg:px-[13rem]">
        <h1 className="text-2xl font-bold text-[#167d5c]">Data Game</h1>
        <form
          onSubmit={handleSubmitGame}
          className="flex flex-col gap-5 w-full"
        >
          <label htmlFor="judulGame" className="font-bold text-md">
            Judul Game
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="judulGame"
            type="text"
            placeholder="Masukan Judul Game"
            value={judulGame}
            onChange={(e) => setJudulGame(e.target.value)}
            required
          />
          <label htmlFor="usernameGame" className="font-bold text-md">
            Username Game
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="usernameGame"
            type="text"
            placeholder="Masukan Username Game"
            value={usernameGame}
            onChange={(e) => setUsernameGame(e.target.value)}
            required
          />
          <label htmlFor="passwordGame" className="font-bold text-md">
            Password Game
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="passwordGame"
            type="text"
            placeholder="Masukan Password Game"
            value={passwordGame}
            onChange={(e) => setPasswordGame(e.target.value)}
            required
          />
          <label htmlFor="emailGame" className="font-bold text-md">
            Email Game
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="emailGame"
            type="text"
            placeholder="Masukan Email Game"
            value={emailGame}
            onChange={(e) => setEmailGame(e.target.value)}
            required
          />
          <label htmlFor="emailPasswordGame" className="font-bold text-md">
            Password Email
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="emailPasswordGame"
            type="text"
            placeholder="Masukan Password Email"
            value={passwordEmailGame}
            onChange={(e) => setPasswordEmailGame(e.target.value)}
            required
          />
          <label htmlFor="hostEmailGame" className="font-bold text-md">
            Host Email
          </label>
          <input
            className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem] mt-[-0.5rem]"
            id="hostEmailGame"
            type="text"
            placeholder="Masukan Host Email"
            value={hostEmailGame}
            onChange={(e) => setHostEmailGame(e.target.value)}
            required
          />

          {isLoadingGame ? (
            <button
              className="bg-[#167d5c] flex items-center gap-1 p-2 rounded-xl text-white font-bold w-[6rem] hover:bg-[#0f5c44]"
              disabled
            >
              <ImSpinner2 className="animate-spin" />
              Loading
            </button>
          ) : (
            <button
              type="submit"
              className="bg-[#167d5c] p-2 rounded-xl text-white font-bold w-[6rem] hover:bg-[#0f5c44]"
            >
              Submit
            </button>
          )}
        </form>
      </div>
    </main>
  );
}
