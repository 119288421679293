import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";

export default function App() {
  const [username, setUsername] = useState("");
  // const noTelpon = "6287879564171";
  const [nomor, setNomor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [kode, setKode] = useState("");
  const [date, setDate] = useState("");
  const [pesanError, setPesanError] = useState("");
  const [success, setSuccess] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNotFound(false);
    setSuccess(false);
    setDate("");
    setIsLoading(true);

    try {
      const response = await axios.get(
        "https://gameotp.rfstore.net/backend/gameotp",
        {
          params: {
            nomor,
            username,
          },
        }
      );
      console.log(response.data.result);

      if (response.data.status === true) {
        setKode(response.data.result.substring(0, 5));
        const startIndex = response.data.result.indexOf("Login");
        setDate(
          startIndex !== -1 ? response.data.result.substring(startIndex) : ""
        );
        setSuccess(true);
      } else {
        setPesanError(response.data.result);
        setNotFound(true);
      }
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy, HH.mm");
  }

  return (
    <main className="p-10">
      <div className="flex flex-col gap-5">
        <h1 className="text-3xl font-bold">
          Request Kode OTP Untuk Login Akun Game
        </h1>
        <p className="text-lg">
          Ini adalah website request kode otp untuk login akun game khusus untuk
          customer RF Store.
        </p>
        <div className="mt-8 flex flex-col gap-7 lg:px-[13rem]">
          <form onSubmit={handleSubmit} className="flex flex-col gap-5 w-full">
            <label htmlFor="username" className="font-bold text-lg">
              Masukan Nomor Transaksi / Nomor Invoice yang Terdaftar
            </label>
            <input
              className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem]"
              id="nomor"
              type="text"
              placeholder="Nomor Transaksi / Nomor Invoice Yang Terdaftar"
              onChange={(e) => setNomor(e.target.value)}
              required
            />
            <label htmlFor="username" className="font-bold text-lg">
              Masukan Username Steam Terdaftar
            </label>
            <input
              className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem]"
              id="username"
              type="text"
              placeholder="Masukan Username Steam"
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-[#164d7d] p-2 rounded-xl text-xl text-white font-bold w-[8rem] hover:bg-[#13436d]"
            >
              Submit
            </button>
            {isLoading && (
              <div className="animate-pulse">
                Silahkan tunggu 15-30 detik...
              </div>
            )}
          </form>

          {success && (
            <>
              <h1 className="text-3xl text-center">Informasi</h1>
              <div className="w-full h-auto bg-green-600/90 rounded-2xl text-white p-5 py-12">
                <p className="text-lg text-center">
                  Kode OTP untuk Login Akun Game adalah:<br></br> <b>{kode}</b>{" "}
                  <br></br> {date} <br /> <br /> DILARANG Sharing Ulang / Dijual
                  Ulang / Dipinjamkan / Login Multi Device <br />
                  <b> MELANGGAR ATURAN AKAN DIBLOKIR AKSES DAN NO REFUND </b>
                </p>
              </div>
            </>
          )}

          {notFound && (
            <>
              <h1 className="text-3xl text-center">Informasi</h1>
              <div className="w-full h-auto bg-red-600/90 rounded-2xl text-white p-5 py-12">
                <p className="text-lg text-center">
                  {pesanError}
                  {/* Belum ada kode OTP yang muncul silahkan coba submit 1-2 menit
                  lagi */}
                </p>
              </div>
            </>
          )}

          {/* <div className="w-full h-auto bg-red-600 rounded-2xl text-white p-5 py-12">
            <p className="text-lg text-center">
              Mohon maaf kamu sudah mencapai batas limit hari ini untuk
              permintaan kode OTP silahkan coba lagi setelah 24 jam atau hubungi
              admin.
            </p>
          </div> */}

          {/* {kodeLink && (
            <div className="border-2 border-grey-500 min-h-[15rem] p-12">
              <span className="flex flex-col gap-5">
                <p className="text-xl">
                  Diminta pada tanggal {formatDate(date)} WIB
                </p>
                <a
                  target="_blank"
                  href={kodeLink}
                  className="font-bold text-xl text-white bg-red-600 p-5 rounded-lg hover:bg-red-800 text-center"
                >
                  <button>Klik Untuk Dapatkan Kode / Access Menonton</button>
                </a>
                <p>
                  *Klik submit kembali jika link tidak bisa atau waktu sudah
                  kadaluarsa lebih dari 15 menit
                </p>
              </span>
            </div>
          )}
          {error && (
            <div className="border-2 border-grey-500 min-h-[10rem] p-12 bg-red-200">
              <span className="flex flex-col gap-5">
                <p className="text-2xl text-center">{error}</p>
              </span>
            </div>
          )} */}
        </div>
      </div>
    </main>
  );
}
